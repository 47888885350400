export const colors = {
  color1: '#f8f8ff',
  color2: '#469af3',
  color3: '#1a1b3f',
  color4: 'rgba(123, 123, 123, 0.9)',
  color5: '#f2b218',
  color6: '#c64415',
};

export const api = 'https://api.pokemontcg.io/v2/';
